import { useSpring } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { StylableProp } from '../../utils/stylable-prop';
import * as style from './index.module.css';
import { AnimatedCounterProps } from './model';

export function AnimatedCounter(props: StylableProp<AnimatedCounterProps>) {
  const { ref, inView } = useInView({ threshold: 1 });
  const classNames = [style.animatedCounter, props.className];
  const decimalMultiplier = props.decimals ? Math.pow(10, props.decimals) : 1;

  if (!props.decimals) {
    classNames.push(style.noDecimals);
  }

  return (
    <span
      ref={ref}
      className={classNames.join(' ')}
      style={{
        ['--value' as any]: inView ? props.value : props.initialValue || 0,
        ['--duration' as any]: props.duration ?? `2s`,
        ['--prefix' as any]: `'${props.prefix ?? ''}'`,
        ['--suffix' as any]: `'${props.suffix ?? ''}'`,
        ['--decimals' as any]: `${decimalMultiplier}`
      }}
    ></span>
  );
}

export function MotionAnimatedCounter(props: StylableProp<AnimatedCounterProps>) {
  const [counter, setCounter] = useState(props.initialValue ?? 0);
  const spring = useSpring(counter, { duration: props.duration ?? 1000 });
  const { ref, inView } = useInView({ threshold: 0.33 });

  useEffect(() => {
    spring.set(inView ? props.value : props.initialValue);
    return spring.onChange(() => setCounter(spring.get()));
  }, [inView, props.value, props.initialValue]);

  return (
    <span ref={ref} className={`${props.className}`}>
      {props.prefix}
      {counter.toLocaleString(undefined, {
        minimumFractionDigits: props.decimals ?? 0,
        maximumFractionDigits: props.decimals ?? 0
      })}
      {props.suffix}
    </span>
  );
}

export default MotionAnimatedCounter;
